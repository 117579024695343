import styles from './PasswordInput.module.css'
import { Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useState } from 'react'

const PasswordInput = ({
	containerClassName,
	className,
	size = 'normal',
	title,
	tooltip,
	loading,
	placeholder,
	readOnly,
	disabled,
	error,
	value,
	onChange
}) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div className={`${styles.passwordInput} ${containerClassName}`}>
			{
				title &&
				<span className={`${styles.title} ${error && styles.titleError}`}>
					{title}
					{
						tooltip &&
						<Tooltip title={tooltip} placement='right'>
							<img
								className={styles.questionMarkIcon}
								src='/img/question-mark-circled.svg'
								alt='Question mark icon'
								width={16}
								height={16}
							/>
						</Tooltip>
					}
				</span>
			}
			{
				loading &&
					<div className={styles.loadingIcon}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div>
			}
			{
				!loading &&
				<>
					{
						showPassword ?
							<img
								className={styles.visibilityToggleIcon}
								src='/img/eye-off.svg'
								alt='Eye icon'
								width={24}
								height={24}
								onClick={() => setShowPassword(false)}
							/> :
							<img
								className={styles.visibilityToggleIcon}
								src='/img/eye.svg'
								alt='Eye icon'
								width={24}
								height={24}
								onClick={() => setShowPassword(true)}
							/>
					}
				</>
			}
			<input
				className={
				`
					${styles.input}
					${size === 'small' ? styles.inputSmall : size === 'large' ? styles.inputLarge : ''} 
					${!title && styles.inputWithoutTitle}
					${(readOnly || disabled) && styles.inputReadOnly}
					${error && styles.inputError}
					${className}
				`
				}
				type={showPassword ? 'text' : 'password'}
				placeholder={placeholder}
				readOnly={readOnly}
				disabled={disabled}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default PasswordInput
